:root {
  --clr-neutral-900: hsl(207, 19%, 9%);
  --clr-neutral-100: hsl(0, 0%, 100%);
  --clr-accent-400: #A52026;
}

.card {
  color: var(--clr-neutral-100);
  background-size: cover;
  /* max-width: 35ch; */
  border-radius: 0.5rem;
  overflow: hidden;
  transition: transform 500ms ease;
}

.card1{
  background-image: url('../Images/card1.jpg');
}

.card2{
  background-image: url('../Images/card2.jpg');
}

.card3{
  background-image: url('../Images/card3.jpg');
}

.card4{
  background-image: url('../Images/card4.jpg');

}

.card5{
  background-image: url('../Images/card5.jpg');

}

.card6{
  background-image: url('../Images/card6.jpg');

}

.kard{
  padding: 10rem 0 0;

}

.card:hover,
.card:focus-within {
  transform: scale(1.02);
}

.card-content {
  --padding: 1.5rem;
  padding: var(--padding);
  background: linear-gradient(
    hsl(0 0% 0% / 0),
    hsl(20 0% 0% / 0.3) 20%,
    hsl(0 0% 0% / 1)
  );
}

.card-title {
  position: relative;
  width: max-content;
  padding-bottom: 10px;
}

.card:hover .card-title::after,
.card:focus-within .card-title::after {
  transform: scaleX(1);
}

.card-title::after {
  content: "";
  position: absolute;
  height: 4px;
  width: calc(100% + var(--padding));
  left: calc(var(--padding) * -1);
  bottom: -2px;
  background: var(--clr-accent-400);
  transform-origin: left;
  transition: transform 500ms ease;
}

@media (hover) {
  .card-content {
    transform: translateY(65%);
    transition: transform 500ms ease;
  }

  .card-content > *:not(.card-title) {
    opacity: 0;
    transition: opacity 500ms linear;
  }

  .card:hover .card-content > *:not(.card-title),
  .card:focus-within .card-content > *:not(.card-title) {
    opacity: 1;
    transition-delay: 700ms;
  }

  .card:hover .card-content,
  .card:focus-within .card-content {
    transform: translateY(0);
    transition-delay: 500ms;
  }

  .card:focus-within .card-content {
    transition-duration: 0ms;
  }

  .card-title::after {
    transform: scaleX(0);
  }
}

.button {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  color: var(--clr-neutral-100);
  background-color: var(--clr-accent-400);
  padding: 0.5em 1.25em;
  border-radius: 0.25rem;
}

.button:hover,
.button:focus {
  color: #A52026;
  background-color: var(--clr-neutral-100);
}

.card-body {
  color: rgb(255, 255, 255/0.85);
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
    transition-delay: 0ms !important;
  }
}