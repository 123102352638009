@media (max-width : 768px) {
  .Navbar{
    .nav-body{
      .nav-logo{
        z-index: 99;
      }

      .nav__active{
        transform: translateX(0) !important;
      }
      .nav-list{
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        background: #000;
        backdrop-filter: saturate(180%) blur(3px) ;
        background: hsla(0,0%,100%,.8) ;
        flex-direction: column;
        justify-content: center;
        transform: translateX(100%);
        transition: 0.5s ease-in !important;

        li{
          a{
            line-height: normal;
          }
  
          .active{
            color: #A52026;
          }
        }
      }
  
      .nav__toggler{
        display: block;
        z-index: 99;
        div{
          transition: 0.3s ease-in;
          width: 2.5rem;
          height: 0.2rem;
          margin: 0.4rem;
          background: #A52026;
        }
      }

      .toggle{
        transition: 0.5s ease-in !important;
        .line1{
          transform: rotate(-45deg) translate(-4px, 5px) !important;
        }

        .line2{
          opacity: 0 !important;
        }

        .line3{
          transform: rotate(45deg) translate(-8px, -9px) !important;
        }
      }
    }
  }

  .Header{
    padding-top: 100px;
    height: 100%;
    background-image: url('./Images/bg-blur.svg');
    background-size: cover;
    margin-bottom: 20px;
    .row{
      height: 100%;
      align-items: normal;
  
      .header-info{
        p{
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          margin-bottom: 20px;
        }
  
        h2{
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          margin-bottom: 20px;
  
          span{
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
          }
        }
  
        h5{
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 20px;
        }
  
        button{
          display: flex;
          padding: 12px 18px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          color: #FFF;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 20px;
        }
      }
  
      .header-img{
        display: none !important;
      }

      .res-img{
        display: block;

        img{
          width: 100%;
        }
      }
    }
  }

  .section-tittle{
    color: #000;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .About{
    margin-bottom: 50px;
    .section-tittle{
      margin-bottom: 50px;
    }


  
    .row{
      align-items: center;
    }
  
    .about-info{
      p{
        color: #3E3E3E;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 15px;
      }
  
      h4{
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 15px;
      }
  
      h5{
        color: #3E3E3E;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 15px;
      }
  
      button{
        display: flex;
        padding: 12px 18px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 30px;
      }
    }
  }

  .Primushestva{
    margin-bottom: 50px;
    .section-tittle{
      margin-bottom: 50px;
    }
  
    .card{
      height: 100%;
      border-radius: 16px;
      border: 2px solid #D9D9D9;
      background: #FFF;
      .card-body{
        padding: 10px 30px;
        text-align: center;
  
        img{
          margin-bottom: 10px;
        }
  
        h3{
          color: #000;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 10px;
        }
  
        p{
          color: #8F8F8F;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }

  .Services{
    margin-bottom: 50px;
    .section-tittle{
      margin-bottom: 30px;
  
      .card{
        position: relative;
        .card-image{
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }
        
      }
    }
  
  }

  .Form{
    margin-bottom: 50px;
    .section-tittle{
      margin-bottom: 30px;
    }
  
    form{
      h3{
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 166.667% */
        letter-spacing: 2px;
        margin-bottom: 30px;
      }
  
      input{
        display: flex;
        padding: 16px;
        align-items: center;
        gap: 40px;
        flex: 1 0 0;
        border-radius: 4px;
        border: 1px solid #D9D9D9;
        background: #FFF;
        color: #AAA;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px; /* 144.444% */
        letter-spacing: -0.6px;
      }
  
      textarea{
        border-radius: 4px;
        border: 1px solid #D9D9D9;
        background: #FFF;
        display: flex;
        padding: 16px;
        height: 145px;
        padding: 16px;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
        color: #AAA;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px; /* 144.444% */
        letter-spacing: -0.6px;
        max-height: 150px;
        min-height: 70px;
      }
  
      button{
        display: flex;
        padding: 12px 18px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .Footer{
    padding: 64px 0px;
    background: #F5F5F5;
  
    h3{
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px; /* 144.444% */
      letter-spacing: -0.6px;
      margin-bottom: 20px;
    }
    .address{
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 20px;
  
      p{
        color: #707378;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px; /* 144.444% */
        letter-spacing: -0.6px;
        margin: 0;
        padding: 0;
      }
    }
  
    .footer-links{
      display: flex;
      justify-content: start;
      gap: 60px;
  
      .footer-list{
        li{
          margin-bottom: 16px;
          a{
            color: var(--Grey-06, #707378);
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px; /* 144.444% */
            letter-spacing: -0.6px;
            cursor: pointer;
            transition: 0,3s ease-in-out;
  
  
            &:hover{
              transition: 0,3s ease-in-out;
              color: #C1272D;
            }
          }
        }
      }
    }
    
  }
}